export default [
  {
    title: 'Tableau de bord',
    route: 'espace-signataire.dashboard',
    icon: 'HomeIcon',
  },
  {
    header: 'Gestion des demandes',
    icon: 'ListIcon',
  },
  {
    title: 'Etat des demandes',
    icon: 'ListIcon',
    children: [
      {
        title: 'Demandes en attente',
        color: 'warning',
        icon: 'DiscIcon',

        route: 'espace-signataire.list-demandes-en-attente',
      },
      {
        title: 'Demandes signées',
        color: 'success',
        icon: 'DiscIcon',

        route: 'espace-signataire.list-demandes-signees',

      },
    ],
  },

]
